import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useResourcesRights from 'hooks/rights/useResourcesRights';
import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import { HelpMenuTableType } from '../../../types/enums/HelpMenuTableType';
import { MultiSelectVideoCategory } from 'components/index';
import { helpCatelogueAction, selectVideoModelFlagAction, updateVideoRowAction } from 'store/actions/helpmenuActions';
import useHelpMenuService from 'features/HelpMenu/hooks/useHelpMenuService';
import Category from '../../../types/entities/VideoCategory';

interface VideoData {
  title: string;
  description: string;
  category: number[];
  videoFile: File | null;
  thumbnail: string;
  filename: string;
  fileSize: number;
}

interface HelpMenuHeaderProps {
  userRoles: any;
  getHelpMenuList: () => Promise<void>; // Adjust type if necessary
}

const HelpMenuVideoHeader: React.FC<HelpMenuHeaderProps> = ({ userRoles, getHelpMenuList }) => {
  const [t] = useTranslation();
  const { dispatch } = useAppState();
  const { helpMenu } = useSelector((state) => state);
  const resourceRight = useResourcesRights();
  const { getCatelogue, createVideo, updateVideo } = useHelpMenuService();

  const [selectedCategories, setSelectedCategories] = useState<Category[]>([]);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const handleVideoCategoryChange = (newCategories: Category[]) => {
    setSelectedCategories(newCategories);
    const newCategoryNames = newCategories.map((item) => item.id);

    setVideoData((prevState) => ({
      ...prevState,
      category: newCategoryNames, // Update categories all at once
    }));
  };

  const handleCategoryAdded = (newCategory: Category) => {
    console.log('New Category Added:', newCategory);
    // Optionally update the categories list here
  };

  const handleCategoryArchived = (category: Category) => {
    console.log('Category Archived:', category);
    // Optionally handle archiving here
  };

  const handleCategoryDeletionAttempt = () => {
    console.log('Attempt to delete a category');
    // Optionally show a confirmation dialog or handle deletion attempt logic
  };
  const [videoId, setVideoId] = useState<number | undefined>(undefined);
  const [videoData, setVideoData] = useState<VideoData>({
    title: '',
    description: '',
    category: [],
    videoFile: null,
    thumbnail: '',
    filename: '',
    fileSize: 0,
  });

  const getCatelogueList = useCallback(async () => {
    const datas = await getCatelogue();
    dispatch(helpCatelogueAction({ datas }));
  }, []);

  useEffect(() => {
    getCatelogueList();
  }, []);

  useEffect(() => {
    if (helpMenu?.updateSelectedVideoModel && helpMenu?.currentTableType === 'Videos') {
      const editVideo = helpMenu?.updateSelectedVideoModel;
      const catelogueIds = editVideo?.categories?.map((item: any) => item.id);
      const commonCategories = helpMenu?.categories?.filter((category) => catelogueIds?.includes(category.id)) || [];
      setSelectedCategories(commonCategories);
      setVideoId(editVideo?.id);
      setVideoData({
        title: editVideo?.title,
        description: editVideo?.description,
        category: catelogueIds,
        videoFile: editVideo.presignedUrl,
        thumbnail: editVideo.fileKey,
        filename: 'UserGuide.mp4',
        fileSize: 0,
      });
    }
  }, [helpMenu?.updateSelectedVideoModel]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setVideoData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    setButtonLoading(true);
    if (file && file.type === 'video/mp4') {
      setVideoData((prevState) => ({
        ...prevState,
        videoFile: file,
        filename: 'UserGuide.mp4',
        fileSize: Math.round(file.size / (1024 * 1024)),
      }));
      // Create a video element to capture a thumbnail

      const video = document.createElement('video');
      const reader = new FileReader();

      reader.onloadend = () => {
        // Load the video data into the video element
        video.src = reader.result as string;

        video.onloadeddata = () => {
          // Once the video is loaded, seek to a specific time (e.g., 1 second)
          video.currentTime = 0.02;

          // When the video has loaded the current time, capture the frame
          video.onseeked = () => {
            console.log('Seek operation completed.');

            // Ensure the seek operation completed and the video has valid dimensions
            if (video.videoWidth === 0 || video.videoHeight === 0) {
              console.error('Video dimensions are not available.');
              return;
            }

            // Create a canvas to draw the video frame
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            if (context) {
              // Set canvas size to video dimensions
              canvas.width = video.videoWidth;
              canvas.height = video.videoHeight;

              // Draw the video frame onto the canvas
              context?.drawImage(video, 0, 0, canvas.width, canvas.height);

              // Get the data URL for the thumbnail image
              const thumbnail = canvas.toDataURL('image/jpeg', 1.0);
              const base64File = thumbnail.split(',')[1];
              // Set the generated thumbnail in the state
              setVideoData((prevState) => ({
                ...prevState,
                thumbnail: base64File, // Store the thumbnail in state
              }));
            }
          };
        };
        setButtonLoading(false);
      };

      // Read the video file as a data URL
      reader.readAsDataURL(file);
    } else {
      // If the file is not an mp4, show an error or handle accordingly
      alert(t('Please select a valid MP4 video file.'));
    }
  };

  const emptyData = () => {
    setVideoData({
      title: '',
      description: '',
      category: [],
      videoFile: null,
      thumbnail: '',
      filename: '',
      fileSize: 0,
    });
  };
  const handleSave = async () => {
    // Handle the save logic here (e.g., send data to the server)
    if (videoId !== undefined) {
      setButtonLoading(true);
      const videoPayload = {
        id: videoId,
        title: videoData?.title,
        description: videoData?.description,
        categoryId: videoData?.category,
      };
      const datas = await updateVideo(videoId, videoPayload, videoData?.thumbnail, videoData?.videoFile);
      if (datas === 'Video Updated successfully') {
        getCatelogueList();
        getHelpMenuList();
      }
      dispatch(selectVideoModelFlagAction(false));
      dispatch(updateVideoRowAction([]));
      setSelectedCategories([]);
      emptyData();
      setButtonLoading(false);
    } else {
      setButtonLoading(true);
      const videoPayload = {
        title: videoData?.title,
        description: videoData?.description,
        categoryId: videoData?.category,
      };
      const datas = await createVideo(videoPayload, videoData?.thumbnail, videoData?.videoFile);
      if (datas === 'Video created successfully') {
        getCatelogueList();
        getHelpMenuList();
      }
      dispatch(selectVideoModelFlagAction(false));
      dispatch(updateVideoRowAction([]));
      setSelectedCategories([]);
      emptyData();
      setButtonLoading(false);
    }
  };

  const handleCancel = () => {
    // setOpenModal(false); // Close the modal without saving
    dispatch(selectVideoModelFlagAction(false));
    dispatch(updateVideoRowAction([]));
    setSelectedCategories([]);
    emptyData();
    setButtonLoading(false);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: { md: 'center' },
        flexDirection: { xs: 'column', md: 'row' },
      }}
    >
      {/* Upload Video Button */}
      {helpMenu.currentTab === HelpMenuTableType.VIDEOS && resourceRight.canUploadVideo && (
        <Button
          variant="contained"
          onClick={() => dispatch(selectVideoModelFlagAction(true))} // Open the modal
          sx={{ mb: { xs: 3, md: 0 } }}
        >
          {t('Upload Video')}
        </Button>
      )}

      {/* Modal for Uploading Video */}
      <Dialog open={helpMenu?.selectVideoModelFlag}>
        <DialogTitle>{t('Upload Video')}</DialogTitle>
        <DialogContent>
          {/* Title */}
          <TextField
            label={t('Title')}
            name="title"
            value={videoData.title}
            onChange={handleChange}
            fullWidth
            required
            sx={{ mb: 2 }}
            inputProps={{ maxLength: 100 }}
          />
          {/* Description */}
          <TextField
            label={t('Description')}
            name="description"
            value={videoData.description}
            onChange={handleChange}
            fullWidth
            multiline
            required
            rows={4}
            sx={{ mb: 2 }}
            inputProps={{ maxLength: 5000 }}
          />
          {/* Category */}

          <MultiSelectVideoCategory
            items={helpMenu?.categories && helpMenu?.categories?.length > 0 ? helpMenu?.categories : []}
            value={selectedCategories}
            onChange={handleVideoCategoryChange}
            selectAllLabel="Select Categories"
            onCategoryAdded={handleCategoryAdded}
            onCategoryArchived={handleCategoryArchived}
            onCategoryDeletionAttempt={handleCategoryDeletionAttempt}
            maximumVisible={3}
          />

          {/* Upload Video File with Custom Button Text */}
          <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 2 }}>
            <Button variant="outlined" component="label" sx={{ mr: 2 }}>
              {t('Upload Video *')}
              <input type="file" name="videoFile" accept="video/mp4" required onChange={handleFileChange} hidden />
            </Button>

            {/* Display selected video file name and size */}
            {videoData.videoFile && (
              <Typography variant="body2" sx={{ ml: 2 }}>
                {videoId ? (
                  <>
                    {t('Selected file:')} {videoData.filename}
                  </>
                ) : (
                  <>
                    {t('Video file:')} {videoData.filename} ({videoData.fileSize} MB)
                  </>
                )}
              </Typography>
            )}
          </Box>
          {videoData.fileSize > 0 && videoData.fileSize >= 600 && (
            <Typography data-testid="error_reported" sx={{ color: 'error.main', mt: 2 }}>
              Please upload less than 600 mb
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="secondary">
            {t('Cancel')}
          </Button>

          <LoadingButton
            disabled={
              videoData?.title === '' ||
              videoData?.category?.length === 0 ||
              videoData?.description === '' ||
              videoData?.thumbnail === '' ||
              videoData?.filename === ''
            }
            onClick={handleSave}
            color="primary"
            variant="contained"
            loading={buttonLoading}
          >
            {videoId ? t('Update') : t('Save')}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default HelpMenuVideoHeader;
