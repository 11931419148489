import ResourceAPI, { Resource } from 'api/ResourceAPI';

export default class RolesApiService {
  public static instance: RolesApiService;

  public static baseUrl = 'roles' as Resource;

  public static get Instance() {
    // eslint-disable-next-line no-return-assign
    return RolesApiService.instance || (RolesApiService.instance = new RolesApiService());
  }

  public static async fetchAll(fromHelpMenu = false) {
    try {
      const { data } = await ResourceAPI.get(this.baseUrl, { size: '-1' });
      const filteredData = fromHelpMenu
        ? data.datas // Do not filter if called from HelpMenu
        : data.datas.filter((item: any) => item.name.toLowerCase() !== 'customer');
      return data.datas;
    } catch (error: unknown) {
      return [];
    }
  }
}
